<template>
  <v-card tile elevation="1" class="fill-height" style="border-left: 3px solid #fafafa; height: 100%" min-height="280">
    <MainModal
        :main="{ component: 'TariffOrganization', title: 'Тарифи по організації' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_tariff_by_org)"
        :modal="show_tariff_by_org_dialog"
        @updateItemModal="tariffByOrgUpdateItemModal"
    />
    <v-card-title class="pa-0">
      <v-toolbar elevation="0" class="pa-0">
        <v-toolbar-title>
          Тарифи по організації
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <YouTubeButton
            button-class="grey lighten-4 mr-2"
            icon-color="error lighten-1"
            :icon-size="26"
            href="https://youtu.be/8vzhYRNXepc"
        />
        <v-btn icon class="grey lighten-4 mr-2" @click="list_view = !list_view">
          <v-icon>
            {{ list_view ? 'mdi-view-list' : 'mdi-format-list-group' }}
          </v-icon>
        </v-btn>
        <v-tooltip bottom color="success">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="openTariffByOrgCreateDialog"
                   v-bind="attrs"
                   v-on="on"
                   class="grey lighten-4 mr-1">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Створити новий тариф по організації</span>
        </v-tooltip>
      </v-toolbar>
    </v-card-title>
    <v-data-table
        :headers="dataHeaders"
        :items="dataItems"
        :group-by="list_view ? 'service_name' : null"
        :show-group-by="false"
        :footer-props="{
                  showFirstLastPage: true,
                  itemsPerPageText: 'Рядків на сторінку',
                  itemsPerPageOptions: [15, 30, -1],

                }"
        class="custom-table custom-table-full custom-table-1"
        @click:row="onTariffByOrgItemClick"
    >
      <template v-slot:group.header="{items, isOpen, toggle}" v-if="list_view">
        <td colspan="7" class="text-start"
            style="padding-left: 8px !important; padding-top: 6px !important; padding-bottom: 6px !important;">
          <v-icon @click="toggle" size="20" class="mr-4">
            {{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
          </v-icon>
          <v-icon
              size="24"
              class="mr-2"
              :color="getAccountIcon(items[0].icon, 'color')"
          >
            {{  getAccountIcon(items[0].icon, 'icon') }}
          </v-icon>
          {{ items[0].service_name }}
        </td>
      </template>
      <template v-slot:item.actual="{ item }">
        <v-simple-checkbox :value="item.actual" disabled/>
      </template>
      <template v-slot:item.service_name="{ item }">
        <v-icon
            size="24"
            class="mr-2"
            :color="getAccountIcon(item.icon, 'color')"
            v-if="!list_view"
        >
          {{  getAccountIcon(item.icon, 'icon') }}
        </v-icon>
        <span :class="!item.actual ? 'font-weight-normal grey--text text--darken-1' : 'font-weight-medium grey--text text--darken-2'">{{ item.service_name }}</span>
      </template>
      <template v-slot:item.date_start="{ item }">
        <span :class="!item.actual ? 'font-weight-normal grey--text text--darken-1' : 'font-weight-medium grey--text text--darken-2'">
          {{ item.date_start | formatDate }}
        </span>
      </template>
      <template v-slot:item.tariff_group_type_name="{ item }">
        <v-chip small :color="!item.actual ? 'grey lighten-3' : 'grey lighten-2'"
                :style="!item.actual ? 'text-decoration: line-through' : ''"
        >
          {{ item.tariff_group_type_name || 'Без групи' }}
        </v-chip>
      </template>
      <template v-slot:item.date_end="{ item }">
        <span :class="!item.actual ? 'font-weight-normal grey--text text--darken-1' : 'font-weight-medium grey--text text--darken-2'">
          {{ item.date_end | formatDate }}
        </span>
      </template>
      <template v-slot:item.value_dec="{ item }">
        <span class="font-weight-medium grey--text text--darken-2">
          {{ item.value_dec | formatNumber }}
        </span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {getAccountIcon} from "@/utils/icons";
import {mapActions, mapGetters} from "vuex";
import {FETCH_TARIFF_BY_ORGANIZATION} from "@/store/actions/tariff_by_organization";
import MainModal from "@/components/modal/MainModal";
import {SELECT_SERVICES_WITHOUT_ALL_SERVICE} from "@/store/actions/services";
import {FETCH_TARIFF_GROUP_SELECT} from "@/store/actions/tariff_group";

export default {
  name: "TariffOrganization",
  props: {
    legacy: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  components: {
    MainModal,
    YouTubeButton: () => import("@/components/YouTubeButton")
  },
  computed: {
    ...mapGetters({
      dataItems: 'getTarByOrg'
    })
  },
  data() {
    return {
      list_view: true,
      dataHeaders: [
        {text: '', value: 'hidden', width: '5px', sortable: false, hidden: true},
        {text: 'Послуга', value: 'service_name'},
        {text: 'Початок', value: 'date_start'},
        {text: 'Закінчення', value: 'date_end'},
        {text: 'Група', value: 'tariff_group_type_name'},
        {text: 'Тариф', value: 'value_dec'},
        {text: 'Тариф 2 зона', value: 'value_dec_second'},
        {text: 'Тариф 3 зона', value: 'value_dec_third'},
      ],
      selected_tariff_by_org: {},
      show_tariff_by_org_dialog: false,
    }
  },
  methods: {
    ...mapActions({
      fetchTariffs: FETCH_TARIFF_BY_ORGANIZATION,
      fetchServices: SELECT_SERVICES_WITHOUT_ALL_SERVICE,
      fetchGroups: FETCH_TARIFF_GROUP_SELECT
    }),
    getAccountIcon,
    tariffByOrgUpdateItemModal() {
      this.show_tariff_by_org_dialog = false
      this.selected_tariff_by_org = {}
    },
    openTariffByOrgCreateDialog() {
      this.selected_tariff_by_org = {}
      this.show_tariff_by_org_dialog = true
    },
    onTariffByOrgItemClick(payload) {
      this.selected_tariff_by_org = JSON.parse(JSON.stringify(payload))
      this.show_tariff_by_org_dialog = true
    },
  },
  created() {
    this.fetchServices()
    this.fetchGroups()
  },
  watch: {
    legacy: {
      immediate: true,
      handler(payload) {
        this.fetchTariffs(payload)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.custom-table {
  &:deep(.v-data-table__wrapper) {
    min-height: 300px !important;
  }
}
</style>